// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../List/variables";

/*
Carousel

Markup:
<div class="cuttable {{.modifier}}">
    <div class="cuttable__content">{{children}}</div>
    <div class="cuttable__action">{{children}}</div>
</div>

Modifiers:
.cuttable--expand
.cuttable--collapse
*/

@cuttable--prefix-cls: ~"@{cls-prefix}cuttable";

.@{cuttable--prefix-cls} {
    &__content {
        overflow: hidden;
    }

    &--expand &__content {
        // stylelint-disable-next-line declaration-no-important
        max-block-size: none !important;
    }

    &--collapse &__content {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            inline-size: 100%;
            inset-inline-start: 0;
            inset-block-end: 0;
            block-size: 1.4em;
            box-shadow: inset 0 -30px 18px -18px #fff;
        }
    }
}

.@{list--prefix-cls}
    tr.is-selected
    > td
    .@{cuttable--prefix-cls}--collapse
    .@{cuttable--prefix-cls}__content::after {
    box-shadow: inset 0 -30px 18px -18px @list--row-background-color-selected;
}

.@{list--prefix-cls}
    tr:not(.is-selected):hover
    > td
    .@{cuttable--prefix-cls}--collapse
    .@{cuttable--prefix-cls}__content::after {
    box-shadow: inset 0 -30px 18px -18px @list--row-background-color-hover;
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;