// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "variables";

.@{checkbox--prefix-cls} {
    display: inline-block;
    position: relative;
    padding-inline-start: 20px;
    min-block-size: 20px;
    line-height: 20px;
    vertical-align: top;
    transition: opacity linear 0.2s;

    input[type="checkbox"]&__input {
        position: absolute;
        inline-size: 1px;
        block-size: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
    }

    &__label {
        cursor: pointer;
    }

    &__indicator {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        box-sizing: border-box;
        inline-size: 20px;
        block-size: 20px;
        overflow: hidden;
        border: 1px solid @checkbox--border-color;
        border-radius: 2px;
        background-color: @checkbox--background-color;
        transition: border-color linear 0.2s, background-color linear 0.2s, box-shadow linear 0.2s;
    }

    &__text {
        display: inline-block;
        margin-inline-start: 8px;
        vertical-align: top;
        white-space: normal;
    }

    &:hover &__indicator {
        border-color: @checkbox--border-color-hover;
    }

    &__input:checked + &__indicator {
        border-color: @checkbox--border-color-checked;
        background-color: @checkbox--background-color-checked;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M13.57 1.18a1 1 0 01.25 1.39l-6.25 9a1 1 0 01-1.54.13L2.28 7.84a1 1 0 111.44-1.4l2.9 3 5.56-8.01a1 1 0 011.39-.25z'/%3E%3C/svg%3E");
        background-position: 1px 2px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
    }

    &__input:focus + &__indicator {
        border-color: @checkbox--border-color-focus;
        box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.24);
        outline: none;
    }

    &__input:disabled + &__indicator {
        border-color: @checkbox--border-color-disabled;
        background-color: @checkbox--background-color-disabled;
    }

    &__input:checked:disabled + &__indicator {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23979797' d='M13.57 1.18a1 1 0 01.25 1.39l-6.25 9a1 1 0 01-1.54.13L2.28 7.84a1 1 0 111.44-1.4l2.9 3 5.56-8.01a1 1 0 011.39-.25z'/%3E%3C/svg%3E");
    }

    &--indeterminate &__indicator {
        border-color: @checkbox--border-color-checked;
        background-color: @checkbox--background-color-checked;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='12' height='2' rx='1' fill='%23fff'/%3E%3C/svg%3E");
        background-position: 50% 50%;
        background-size: 12px 2px;
        background-repeat: no-repeat;
    }

    &--indeterminate &__input:disabled + &__indicator {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='12' height='2' rx='1' fill='%23979797'/%3E%3C/svg%3E");
    }

    &--disabled {
        opacity: 0.47;
        cursor: not-allowed;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;