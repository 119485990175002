// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Overlay/variables";
@import "../Button/variables";

@drawer-header--prefix-cls: ~"@{cls-prefix}drawer-header";

@drawer-header--color: #fff;
@drawer-header--background-color: @token-color-dark-1;
@drawer-header--link-color: @token-color-primary-5;

.@{drawer-header--prefix-cls} {
    display: flex;
    flex: none;
    align-items: center;
    padding-block: 8px;
    padding-inline: 16px;
    color: @drawer-header--color;
    background-color: @drawer-header--background-color;

    &__title {
        flex-grow: 1;
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        color: @drawer-header--color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__back-button {
        margin-inline-start: -4px;
        margin-inline-end: 12px;
    }

    .@{button--prefix-cls}--ghost {
        .@{overlay--prefix-cls}--right & .@{button--prefix-cls}__icon {
            transform: scale(-1);
        }
        .@{button--prefix-cls}__icon {
            color: rgba(255, 255, 255, 0.65);
        }
    }

    .@{button--prefix-cls}--ghost:hover,
    .@{button--prefix-cls}--ghost:focus,
    .@{button--prefix-cls}--ghost:active {
        background-color: rgba(0, 0, 0, 0.05);
        .@{button--prefix-cls}__icon {
            color: rgba(255, 255, 255, 0.9);
        }
    }

    .@{button--prefix-cls}--ghost:active {
        background-color: rgba(0, 0, 0, 0.09);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;