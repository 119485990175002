// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../../helpers/z-indexes";
@import "../Input/variables";

.@{search-bar--prefix-cls} {
    inline-size: 100%;
    position: relative;

    &__group {
        inline-size: 100%;
        position: relative;
        z-index: 2;
    }

    &__results {
        position: absolute;
        inset-inline-end: 0;
        z-index: @search-bar--menu-z-index;
        margin-block: 4px;
        margin-inline: 0;
        inline-size: 100%;

        &--hidden {
            display: none;
        }
    }

    &__group > .@{search-bar--prefix-cls}__input {
        .@{input--prefix-cls}__input {
            block-size: 28px;
            padding-inline-end: 57px;
            transition: none;
        }

        .@{input--prefix-cls}__suffix {
            inline-size: auto;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;