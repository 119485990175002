// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{input-file--prefix-cls} {
    display: inline-flex;
    align-items: center;
    max-inline-size: 100%;

    &__input {
        position: absolute;
        inline-size: 1px;
        block-size: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
    }

    &__button {
        flex-shrink: 0;
    }

    &__name {
        margin-block: 0;
        margin-inline: 8px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;