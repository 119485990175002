// Copyright 1999-2018. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

@content-loader--prefix-cls: ~"@{cls-prefix}content-loader";

.@{content-loader--prefix-cls} {
    &:not(&--overlay) {
        position: relative;
        overflow: hidden;
    }

    &__content--blur {
        filter: blur(2px);
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }

    &__overlay {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
    }

    &__backdrop {
        background: rgba(255, 255, 255, 0.8)
            url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCI+PHBhdGggZD0iTTktMWwyIDJNMCAwbDEwIDEwTS0xIDlsMiAyIiBzdHJva2U9InJnYmEoMCwwLDAsMC4wNSkiLz48L3N2Zz4=");
        background-size: 10px 10px;
    }

    &__loader {
        display: flex;
        align-items: center;
    }

    &__loader--spinner &__indicator {
        flex: none;
        inline-size: 24px;
        block-size: 24px;

        &--sm {
            inline-size: 16px;
            block-size: 16px;
        }
    }

    &__loader--spinner &__text {
        margin-inline-start: 10px;
    }

    &__loader--icons {
        flex-direction: column;
    }

    &__loader--icons &__indicator {
        margin-block-end: 18px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;