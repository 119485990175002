// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Icon/variables";

.@{heading--prefix-cls} {
    margin-block-start: 0;
    margin-block-end: 8px;
    font-family: @heading--font-family;
    font-weight: @heading--font-weight;
    color: @heading--color;

    &--h1 {
        font-size: @heading--h1-font-size;
        line-height: @heading--h1-line-height;
    }

    &--h1 &__icon {
        margin-inline-end: 8px;

        &.@{icon--prefix-cls}--size-12 {
            margin-block-start: 14px;
        }

        &.@{icon--prefix-cls}--size-16 {
            margin-block-start: 12px;
        }

        &.@{icon--prefix-cls}--size-24 {
            margin-block-start: 8px;
        }

        &.@{icon--prefix-cls}--size-32 {
            margin-block-start: 4px;
        }
    }

    &--h2 {
        font-size: @heading--h2-font-size;
        line-height: @heading--h2-line-height;
    }

    &--h2 &__icon {
        margin-inline-end: 8px;

        &.@{icon--prefix-cls}--size-12 {
            margin-block-start: 10px;
        }

        &.@{icon--prefix-cls}--size-16 {
            margin-block-start: 8px;
        }

        &.@{icon--prefix-cls}--size-24 {
            margin-block-start: 4px;
        }
    }

    &--h3 {
        font-size: @heading--h3-font-size;
        line-height: @heading--h3-line-height;
    }

    &--h3 &__icon {
        margin-inline-end: 8px;

        &.@{icon--prefix-cls}--size-12 {
            margin-block-start: 7px;
        }

        &.@{icon--prefix-cls}--size-16 {
            margin-block-start: 5px;
        }

        &.@{icon--prefix-cls}--size-24 {
            margin-block-start: 1px;
        }
    }

    &--h4 {
        font-size: @heading--h4-font-size;
        line-height: @heading--h4-line-height;
    }

    &--h4 &__icon {
        margin-inline-end: 6px;

        &.@{icon--prefix-cls}--size-12 {
            margin-block-start: 4px;
        }

        &.@{icon--prefix-cls}--size-16 {
            margin-block-start: 2px;
        }
    }

    &--h5 {
        font-size: @heading--h5-font-size;
        line-height: @heading--h5-line-height;
    }

    &--h5 &__icon {
        margin-inline-end: 6px;

        &.@{icon--prefix-cls}--size-12 {
            margin-block-start: 4px;
        }

        &.@{icon--prefix-cls}--size-16 {
            margin-block-start: 2px;
        }
    }

    &--h6 {
        font-size: @heading--h6-font-size;
        line-height: @heading--h6-line-height;
    }

    &--h6 &__icon {
        margin-inline-end: 4px;

        &.@{icon--prefix-cls}--size-12 {
            margin-block-start: 2px;
        }
    }

    &--h4,
    &--h5,
    &--h6 {
        margin-block-end: 4px;
    }

    &--icon {
        display: flex;
        align-items: center;
    }

    &__icon {
        flex: none;
        align-self: flex-start;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;