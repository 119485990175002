// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Overlay/variables";
@import "../Button/variables";
@import "../Icon/variables";
@import "../Action/variables";
@import "../Section/variables";

@dialog--prefix-cls: ~"@{cls-prefix}dialog";

@dialog--background-color: @token-color-grey-1;
@dialog--heading-background-color: @token-color-dark-1;
@dialog--heading-color: #fff;
@dialog--heading-link-color: @token-color-primary-5;
@dialog--heading-link-color-hover: @token-color-primary-6;
@dialog--heading-link-color-active: @token-color-primary-7;

.@{dialog--prefix-cls} {
    .@{overlay--prefix-cls}__content {
        border-radius: 4px;
        background-color: transparent;
    }

    &__heading {
        display: flex;
        flex: none;
        align-items: center;
        padding-block: 8px;
        padding-inline: 16px;
        border-start-start-radius: 4px;
        border-start-end-radius: 4px;
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        font-size: 12px;
        font-weight: 400;
        color: @dialog--heading-color;
        background-color: @dialog--heading-background-color;

        a,
        a .@{icon--prefix-cls},
        a .@{action--prefix-cls}__icon,
        .@{button--prefix-cls}--ghost {
            color: @dialog--heading-link-color;
        }

        a:hover,
        a:hover .@{icon--prefix-cls},
        a:hover .@{action--prefix-cls}__icon,
        .@{button--prefix-cls}--ghost:hover {
            color: @dialog--heading-link-color-hover;
        }

        a:active,
        a:active .@{icon--prefix-cls},
        a:active .@{action--prefix-cls}__icon,
        .@{button--prefix-cls}--ghost:active {
            color: @dialog--heading-link-color-active;
        }
    }

    &__heading .@{button--prefix-cls}--ghost {
        .@{overlay--prefix-cls}--right & .@{button--prefix-cls}__icon {
            transform: scale(-1);
        }
        .@{button--prefix-cls}__icon {
            color: rgba(255, 255, 255, 0.65);
        }
    }

    &__heading .@{button--prefix-cls}--ghost:hover,
    &__heading .@{button--prefix-cls}--ghost:focus,
    &__heading .@{button--prefix-cls}--ghost:active {
        background-color: rgba(0, 0, 0, 0.05);
        .@{button--prefix-cls}__icon {
            color: rgba(255, 255, 255, 0.9);
        }
    }

    &__heading .@{button--prefix-cls}--ghost:active {
        background-color: rgba(0, 0, 0, 0.09);
    }

    &__heading-close {
        margin-block: 0;
        margin-inline: 8px -4px;
    }

    &__heading-title {
        flex: auto;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        color: @dialog--heading-color;
    }

    &__heading-actions {
        margin-inline-start: 8px;
    }

    &__banner {
        flex: none;
    }

    &__banner,
    &__image {
        text-align: center;
    }

    &__banner-inner {
        inline-size: 100%;
        block-size: auto;
    }

    &__image {
        padding-block-end: 16px;
    }

    &__image-inner {
        max-inline-size: 100%;
        block-size: auto;
    }

    &__content {
        padding: 16px;
        max-block-size: ~"calc(100vh - 112px)";
        overflow-y: auto;
        word-break: break-word;
        background-color: @dialog--background-color;
    }

    &__buttons {
        margin-block-start: 16px;
        padding-block-start: 0;
        text-align: center;

        .@{button--prefix-cls} {
            min-inline-size: 90px;
            margin-block: 0;
            margin-inline: 0 16px;
            padding-block: 10px;
            padding-inline: 24px;
            font-weight: 600;
            font-size: 14px;
        }
    }

    &__form-footer,
    &__buttons {
        position: sticky;
        inset-block-end: -16px;
        margin: logical 16px -16px -16px;
        padding: logical 16px 16px 24px;
        border-block-start: 1px solid @token-color-grey-3;
        background-color: #f5f5f5;
        text-align: start;
    }

    .@{section-item--prefix-cls}:not(.@{section-item--prefix-cls}--vertical)
        .@{section-item--prefix-cls}__title {
        inline-size: 160px;
    }

    &__form-footer
        .@{section-item--prefix-cls}:not(.@{section-item--prefix-cls}--vertical)
        .@{section-item--prefix-cls}__title {
        order: 1;
        padding-inline-end: 0;
        padding-inline-start: 16px;
        text-align: end;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;