// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Grid/variables";
@import "../Item/variables";
@import "../ItemLink/variables";
@import "../Media/variables";
@import "../Icon/variables";

@item-list--prefix-cls: ~"@{cls-prefix}item-list";

@item-list--item-background-color-hover: @token-color-primary-0;
@item-list--item-shadow: @token-shadow-4;
@item-list--item-shadow-hover: @token-shadow-9;
@item-list--item-background-color: #fff;
@item-list--item-icon-color: @token-color-grey-8;
@item-list--item-icon-color-hover: @base-body-color;
@item-list--selected-item-background-color: @token-color-grey-3;
@item-list--selected-item-shadow: @token-shadow-10;

.@{item-list--prefix-cls} {
    &__item {
        .@{media--prefix-cls}__figure {
            color: @item-list--item-icon-color;
        }
        .@{media--prefix-cls}__figure .@{icon--prefix-cls}--size-16 {
            margin-inline-end: 4px;
        }

        .@{item--prefix-cls}__title {
            color: @base-body-color;
            text-decoration: none;
        }
    }

    &--stretchable {
        .@{item-list--prefix-cls}__col,
        .@{item-list--prefix-cls}__item {
            display: flex;
            flex-direction: column;
        }

        .@{item-list--prefix-cls}__item,
        .@{item--prefix-cls}__media {
            flex: 1 1 auto;
        }
    }

    &--hoverable:not(&--cards) {
        margin-inline-start: -8px;
        margin-inline-end: -8px;
    }

    &--hoverable &__item {
        padding: 8px;
        border-radius: 2px;

        &:not(.@{item--prefix-cls}--disabled) {
            cursor: pointer;

            &:hover {
                background: @item-list--item-background-color-hover;
                transition: background-color 0.25s, box-shadow 0.25s;
            }

            &:hover .@{item--prefix-cls}__title {
                color: @base-body-color;
                text-decoration: none;
            }

            &.@{item--prefix-cls}--card:hover {
                box-shadow: @item-list--item-shadow-hover;
            }

            &.@{item--prefix-cls}--card:focus {
                box-shadow: @item--shadow-focus;
            }

            &:hover .@{media--prefix-cls}__figure {
                color: @item-list--item-icon-color-hover;
            }
        }
    }

    & &__item.@{item--prefix-cls}--card {
        padding-block: 16px;
        padding-inline: 20px;
    }

    &--selectable &__item {
        padding-block: 12px 16px;
        padding-inline: 16px;
        cursor: pointer;
        border-radius: 2px;
        background-color: @item-list--item-background-color;
        box-shadow: @item-list--item-shadow;
        transition: background-color 0.25s, box-shadow 0.25s;

        &:hover {
            background-color: @item-list--item-background-color-hover;
            box-shadow: @item-list--item-shadow-hover;
        }

        &:focus {
            box-shadow: @item--shadow-focus;
        }

        &.@{item--prefix-cls}--disabled:hover,
        &.@{item--prefix-cls}--disabled:focus {
            background-color: @item-list--item-background-color;
            box-shadow: @item-list--item-shadow;
        }

        &--selected,
        &--selected:hover,
        &--selected:focus,
        &:active {
            background-color: @item-list--selected-item-background-color;
            box-shadow: @item-list--selected-item-shadow;
        }

        &--selected:focus {
            box-shadow: @item-list--selected-item-shadow, @item--shadow-focus;
        }

        &--selected.@{item--prefix-cls}--disabled:hover,
        &--selected.@{item--prefix-cls}--disabled:focus {
            background-color: @item-list--selected-item-background-color;
            box-shadow: @item-list--selected-item-shadow;
        }
    }

    & &__item.@{item-link--prefix-cls} {
        background-color: @item-link--background-color;
        box-shadow: none;

        &:hover,
        &:active,
        &:focus {
            background-color: @item-link--background-color;
            box-shadow: none;
        }

        &:focus {
            box-shadow: @item-link--shadow-focus;
        }
    }
}

.@{item-list--prefix-cls}__item--reorderable {
    position: relative;
    padding-block: 12px 16px;
    padding-inline: 16px;
    border-radius: 2px;
    background-clip: padding-box;
    cursor: move;
    user-select: none;
    background-color: @item-list--item-background-color-hover;
    box-shadow: @item-list--item-shadow-hover;
    transition: background-color 0.25s, box-shadow 0.25s;

    &::before {
        content: "";
        position: absolute;
        inset-inline-start: 4px;
        inset-block-start: 50%;
        inline-size: 6px;
        block-size: 14px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 14'%3E%3Cpath fill='%23b5b5b5' d='M0 0h2v2H0zM0 4h2v2H0zM0 8h2v2H0zM0 12h2v2H0zM4 0h2v2H4zM4 4h2v2H4zM4 8h2v2H4zM4 12h2v2H4z'/%3E%3C/svg%3E");
        transform: translateY(-50%);
        pointer-events: none;
        transition: opacity 0.25s;
    }

    img {
        pointer-events: none;
    }

    .@{item-list--prefix-cls} & {
        background-color: @item-list--item-background-color;
        box-shadow: @item-list--item-shadow;

        &:not(.@{item--prefix-cls}--disabled) {
            &:hover {
                background-color: @item-list--item-background-color-hover;
                box-shadow: @item-list--item-shadow-hover;
            }

            &:hover::before {
                opacity: 1;
            }
        }

        &::before {
            opacity: 0;
        }
    }
}

.@{item-list--prefix-cls}__col {
    &--gap-xs {
        padding-inline-start: @grid--gap-xs;
        padding-block-end: @grid--gap-xs;
    }

    &--gap-sm {
        padding-inline-start: @grid--gap-sm;
        padding-block-end: @grid--gap-sm;
    }

    &--gap-md {
        padding-inline-start: @grid--gap-md;
        padding-block-end: @grid--gap-md;
    }

    &--gap-lg {
        padding-inline-start: @grid--gap-lg;
        padding-block-end: @grid--gap-lg;
    }

    &--gap-xl {
        padding-inline-start: @grid--gap-xl;
        padding-block-end: @grid--gap-xl;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;