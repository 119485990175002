// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "variables";

.@{overlay--prefix-cls} {
    inset-block-start: 0;
    inset-inline-start: 0;
    inline-size: 100%;
    block-size: 100%;
    display: flex;
    position: fixed;

    &__content {
        position: relative;
        overflow: auto;
        flex-direction: column;
        display: flex;
        outline: none;
        background-color: #fbfbfb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &--left {
        flex: 1 0 auto;
        flex-direction: row;
    }

    &--left &__content {
        box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
        transform: translate(-100%);
    }

    &--left &__content&--enter {
        transform: translate(-100%);
    }

    &--left &__content&--enter-active {
        transform: translate(0);
        transition: transform 300ms ease-in-out;
    }

    &--left &__content&--enter-done,
    &--left &__content&--exit {
        transform: translate(0);
    }

    &--left &__content&--exit-active {
        transform: translate(-100%);
        transition: transform 300ms ease-in-out;
    }

    &--right {
        flex: 1 0 auto;
        flex-direction: row-reverse;
    }

    &--right &__content {
        box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
        transform: translate(100%);
    }

    &--right &__content&--enter {
        transform: translate(100%);
    }

    &--right &__content&--enter-active {
        transform: translate(0);
        transition: transform 300ms ease-in-out;
    }

    &--right &__content&--enter-done,
    &--right &__content&--exit {
        transform: translate(0);
    }

    &--right &__content&--exit-active {
        transform: translate(100%);
        transition: transform 300ms ease-in-out;
    }

    &--center {
        justify-content: center;
        align-items: center;
    }

    &--center &__content {
        max-block-size: 90%;
        margin: logical 0 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        opacity: 0;

        @media (max-width: @screen-xs) {
            margin-inline: 16px;
            max-block-size: none;
        }
    }

    &--center &__content&--enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    &--center &__content&--enter-done,
    &--center &__content&--exit {
        opacity: 1;
    }

    &--center &__content&--exit-active {
        opacity: 0;
        transition: opacity 300ms ease-in;
    }

    &--xs &__content {
        flex-basis: 510px;
    }

    &--sm &__content {
        flex-basis: 640px;
    }

    &--md &__content {
        flex-basis: 900px;
    }

    &--lg &__content {
        flex-basis: 1200px;
    }

    &__backdrop {
        position: fixed;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        opacity: 0;
        background-color: rgba(34, 34, 34, 0.7);
        overflow: auto;
        user-select: none;
        transition: opacity 300ms linear;
    }

    &__backdrop&--enter-active,
    &__backdrop&--enter-done {
        opacity: 1;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;