// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{list-actions--prefix-cls} {
    display: inline-flex;
    vertical-align: top;

    &__menu {
        margin-inline-start: 8px;
    }

    &__hidden-action {
        inline-size: 28px;
        block-size: 28px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;