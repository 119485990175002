// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../FormField/variables";
@import "../Input/variables";

.@{select-control--prefix-cls} {
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    min-block-size: @select--height;
    line-height: @select--line-height;
    inline-size: 100%;
    padding-block: @select--padding-vertical;
    padding-inline: @select--padding-horizontal;
    border: 1px solid @select--border-color;
    border-radius: 2px;
    background-color: @select--background-color;
    color: @select--color;
    box-shadow: @select--shadow;
    transition: all linear 0.2s;
    cursor: default;

    &:hover,
    &:active {
        border-color: @select--border-color-hover;

        .@{select-control--prefix-cls}__indicator--dropdown {
            color: @select--control-color-hover;
        }
    }

    &:focus,
    &--focused {
        border-color: @input--border-color-focus;
        background-color: @input--background-color-focus;
        color: @input--color-focus;
        box-shadow: @input--shadow-focus;
        outline: 0;

        .@{select-control--prefix-cls}__indicator--dropdown {
            color: @select--control-color-hover;
        }
    }

    &--disabled {
        &,
        &:hover,
        &:focus,
        &:active {
            border-color: @select--border-color-disabled;
            background-color: @select--background-color-disabled;
            color: @select--color-disabled;
            opacity: 1;
            cursor: not-allowed;

            .@{select-control--prefix-cls}__indicator {
                color: @select--control-color;
                pointer-events: none;
            }
        }
    }

    &__value-container {
        position: relative;
        flex: 1 1 auto;
        display: flex;
        overflow: hidden;
    }

    &--multiple &__value-container {
        flex-wrap: wrap;
    }

    & input&__input[type="text"] {
        box-sizing: border-box;
        inline-size: 2px;
        block-size: auto;
        min-block-size: @select--line-height;
        line-height: @select--line-height;
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        background: none;
        color: @select--color;
        box-shadow: none;
        outline: none;
        cursor: default;
        z-index: 2;

        &::-ms-clear {
            display: none;
        }

        &:focus {
            border-color: transparent;
            background-color: transparent;
            box-shadow: none;
        }
    }

    &__value,
    &__placeholder {
        max-inline-size: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        pointer-events: none;

        .@{select-control--prefix-cls}__input + & {
            margin-inline-start: -2px;
            max-inline-size: ~"calc(100% + 2px)";
        }
    }

    &__placeholder {
        color: @select--placeholder-color;
    }

    &__indicators {
        margin-block: 0;
        margin-inline: 4px -2px;
        white-space: nowrap;
    }

    &__indicator {
        display: inline-block;
        border: none;
        padding: 0;
        margin-block: 0;
        margin-inline: 2px 0;
        text-decoration: none;
        background: none;
        color: @select--control-color;
        font-family: inherit;
        font-size: inherit;
        cursor: pointer;
        text-align: center;
        transition: none;
        appearance: none;
        line-height: 1;

        &:hover,
        &:focus,
        &:active {
            background: none;
            outline: none;
            transform: none;
            color: @select--control-color-hover;
        }

        &--dropdown {
            pointer-events: none;
        }
    }

    .@{form-field--prefix-cls}--error & {
        &,
        &:hover,
        &:focus {
            border-color: @select--border-color-error;
            background-color: @select--background-color-error;
            box-shadow: @select--shadow;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;