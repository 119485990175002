// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Button/variables";
@import "variables";

.@{spot-popup--prefix-cls} {
    &--xs {
        max-inline-size: 240px;
    }

    &--sm {
        max-inline-size: 320px;
    }

    &--md {
        max-inline-size: 400px;
    }

    &__media {
        margin-block: -6px 16px;
        margin-inline: -6px;
        text-align: center;
        border-radius: 2px;
        overflow: hidden;
        background-color: #fff;

        img,
        video {
            max-inline-size: 100%;
        }

        &--compact {
            margin-inline-end: 0;
        }
    }

    a {
        color: @base-link-color-on-dark;
    }

    a:hover {
        color: @base-link-hover-color-on-dark;
    }

    a:active,
    a:focus {
        color: @base-link-active-color-on-dark;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-start: 16px;
    }

    &__actions .@{button--prefix-cls} {
        margin-inline-start: 8px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;