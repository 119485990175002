// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "variables";

.@{section-item--prefix-cls} {
    padding: logical 4px 0;

    &:not(:last-child) {
        margin-block-end: 8px;
    }

    &__title,
    &__value {
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    &__value {
        min-inline-size: 0;
    }

    &--vertical &__title {
        margin-block-end: 4px;
        font-size: 12px;
    }

    &--vertical &__value {
        font-size: 14px;
        line-height: 20px;
    }

    &:not(&--vertical) {
        display: flex;
        align-items: baseline;
    }

    &:not(&--vertical) &__title {
        flex: none;
        inline-size: 200px;
        padding-inline-end: 16px;
    }

    &:not(&--vertical) &__value {
        flex: auto;
    }

    &--actions {
        padding-block-start: 8px;
        padding-block-end: 28px;
    }

    .@{section--prefix-cls}--collapsible &:not(&--vertical) &__title {
        inline-size: 168px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;