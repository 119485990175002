// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../FormField/variables";
@import "../Icon/variables";
@import "../Button/variables";
@import "../Link/variables";

.@{input--prefix-cls} {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    max-inline-size: 100%;
    vertical-align: middle;
    transition: all linear 0.2s;

    &__input {
        box-sizing: border-box;
        min-block-size: @input--height;
        inline-size: 100%;
        padding-block: @input--padding-vertical;
        padding-inline: @input--padding-horizontal;
        border: 1px solid @input--border-color;
        border-radius: 2px;
        background-color: @input--background-color;
        color: @input--color;
        box-shadow: @input--shadow;
        transition: all linear 0.2s;

        &::-ms-clear {
            display: none;
        }
    }

    &:hover &__input {
        border-color: @input--border-color-hover;
    }

    &__input:focus {
        border-color: @input--border-color-focus;
        background-color: @input--background-color-focus;
        color: @input--color-focus;
        box-shadow: @input--shadow-focus;
        outline: 0;
    }

    &__input[readonly] {
        border-color: @input--border-color;
        background-color: @input--background-color;
        color: @input--color;
        box-shadow: @input--shadow;
    }

    &__input[disabled],
    &:hover &__input[disabled] {
        border-color: @input--border-color-disabled;
        background-color: @input--background-color-disabled;
        color: @input--color-disabled;
        opacity: 1;
        box-shadow: @input--shadow;
    }

    &--size-sm {
        inline-size: @input--size-sm;
    }
    &--size-md {
        inline-size: @input--size-md;
    }
    &--size-lg {
        inline-size: @input--size-lg;
    }
    &--size-xl {
        inline-size: @input--size-xl;
    }
    &--size-fill {
        inline-size: @input--size-fill;
    }

    &--text-left &__input {
        &,
        &::placeholder {
            text-align: start;
        }
    }

    &--text-center &__input {
        &,
        &::placeholder {
            text-align: center;
        }
    }

    &--text-right &__input {
        &,
        &::placeholder {
            text-align: end;
        }
    }

    &--affix &__input {
        &:not(:first-child) {
            padding-inline-start: 28px;
        }
        &:not(:last-child) {
            padding-inline-end: 28px;
        }
    }

    &__prefix,
    &__suffix {
        display: flex;
        position: absolute;
        inset-block-start: 50%;
        transform: translateY(-50%);
        align-items: center;
        justify-content: center;
        line-height: 1;
        inline-size: 28px;
        block-size: 28px;

        > .@{icon--prefix-cls} {
            color: @text-muted-color;
        }

        .@{link--prefix-cls} > .@{icon--prefix-cls} {
            padding: 6px;
        }
    }

    &__prefix {
        inset-inline-start: 0;
    }
    &__suffix {
        inset-inline-end: 0;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;