// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

@figure--prefix-cls: ~"@{cls-prefix}figure";

.@{figure--prefix-cls} {
    position: relative;
    display: inline-block;

    &--slide {
        margin: 0;
    }

    &__caption {
        padding-block-start: 5px;
    }

    &__overlay {
        position: absolute;
        padding: 16px;
    }

    &__overlay--top {
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-inline-start: 0;
    }

    &__overlay--bottom {
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
    }

    &__overlay--cover {
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
    }

    &__action {
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0.8) 100%
        );
        cursor: pointer;
    }

    &__action:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }

    &__action + &__overlay {
        pointer-events: none;
        line-height: 1.3;
        font-weight: 300;
        font-size: 12px;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.8);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;