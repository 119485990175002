// Copyright 1999-2019. Plesk International GmbH. All rights reserved.
@import "./variables.less";

.@{in-place-edit--prefix-cls} {
    display: inline-flex;
    align-items: center;

    &__edit,
    &__edit-actions {
        display: inline-flex;
        vertical-align: top;
    }

    &__control-button--edit,
    &__edit-actions {
        margin-inline-start: 4px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;