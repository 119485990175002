// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{switches-panel-item--prefix-cls} {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    inline-size: 220px;
    min-block-size: 47px;
    padding: 16px;
    border-radius: 4px;
    vertical-align: top;
    background-color: @switches-panel-item--background-color;
    box-shadow: @switches-panel-item--shadow;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.2), 0 2px 8px 4px rgba(60, 64, 67, 0.1);
    }

    &__title {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: @switches-panel-item--color;
    }

    &__description {
        max-block-size: 51px;
        margin-block: 4px 0;
        margin-inline: 0;
        font-size: 12px;
        line-height: 16px;
        color: @switches-panel-item--color;
        overflow: hidden;
    }

    &__actions {
        display: flex;
        margin-block: 8px 0;
        margin-inline: 0;
    }

    &__switch {
        flex: 0 0 auto;
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
    }

    &__controls {
        flex: 1 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__full-description-toggle {
        margin-inline-start: 4px;
    }

    &--warning {
        background-color: @switches-panel-item--warning-background-color;
    }

    &--danger {
        background-color: @switches-panel-item--danger-background-color;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;