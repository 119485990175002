// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "./variables";

.@{link--prefix-cls} {
    color: @link--color;
    text-decoration: @link--text-decoration;
    cursor: pointer;

    &:hover {
        color: @link--hover-color;
        text-decoration: @link--hover-text-decoration;
    }

    &--disabled {
        &,
        &:hover {
            color: @link--disabled-color;
            cursor: not-allowed;
            text-decoration: @link--text-decoration;
        }
    }

    &--pseudo {
        border-block-end: 1px dotted fade(@link--color, 47%);

        &:hover {
            text-decoration: none;
            border-color: @link--hover-color;
        }
    }

    &--pseudo&--disabled {
        border-color: fade(@link--disabled-color, 47%);
    }

    &__external-icon {
        inline-size: 0.923em;
        block-size: 0.923em;
        margin-inline-start: 0.3em;
        vertical-align: 0;
        fill: currentColor;
    }
}

// stylelint-disable-next-line no-duplicate-selectors
.@{link--prefix-cls} {
    // on-dark
    &--on-dark {
        color: @link--on-dark-color;
    }

    &--on-dark:hover {
        color: @link--on-dark-hover-color;
    }

    &--on-dark:active {
        color: @link--on-dark-active-color;
    }

    &--on-dark&--disabled {
        &,
        &:hover {
            color: @link--disabled-color;
        }
    }

    &--on-dark&--pseudo {
        border-block-end-color: fade(@link--on-dark-color, 47%);

        &:hover {
            border-color: @link--on-dark-hover-color;
        }
    }

    &--on-dark&--pseudo&--disabled {
        border-color: fade(@link--disabled-color, 47%);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;