// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Input/variables";

@input-number--prefix-cls: ~"@{cls-prefix}input-number";

.@{input-number--prefix-cls}.@{input--prefix-cls}--size-sm {
    inline-size: 100px;
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;