// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../../helpers/mixins";
@import "variables";

.@{list--prefix-cls} {
    margin-block-end: 16px;

    &__table-container {
        display: block;
        inline-size: 100%;
        overflow-x: auto;
        transition: opacity 0.3s;

        &--loading {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    &__table {
        inline-size: 100%;
        border-block-end: 1px solid @list--border-color;
        border-collapse: separate;
    }

    th,
    td {
        padding: logical 10px 8px;
        vertical-align: top;
        border-block-start: 1px solid @list--border-color;
    }

    td {
        background-color: @list--row-background-color;
    }

    th {
        font-weight: 400;
        text-align: start;
        white-space: nowrap;
    }

    &__table:not(&__table--vertical) > thead > tr > th,
    &__table:not(&__table--vertical) > tbody > tr > td {
        &:not(.@{list--prefix-cls}__cell-reorderable):first-child {
            padding-inline-start: 16px;
        }

        &:last-child {
            padding-inline-end: 16px;
        }
    }

    &__table:not(&__table--vertical)
        > tbody
        > .@{list--prefix-cls}__row--child
        > td.@{list--prefix-cls}__cell-first {
        padding-inline-start: 24px;
    }

    &__table:not(&__table--vertical)
        > tbody
        > .@{list--prefix-cls}__row--child
        > td.@{list--prefix-cls}__cell-first:first-child {
        padding-inline-start: 32px;
    }

    thead th {
        vertical-align: bottom;
        border-block-end: 1px solid @list--border-color;
    }

    tr:first-child > th {
        border-block-start: 0;
    }

    .cell-nowrap {
        white-space: nowrap;
    }

    .cell-shrink {
        inline-size: 1%;
    }

    tbody tr.is-selected {
        > td,
        > th {
            background-color: @list--row-background-color-selected;
        }
    }

    tbody
        tr:not(.is-selected):not(.@{list--prefix-cls}__row-body):not(.@{list--prefix-cls}__row--virtual):hover {
        > td,
        > th {
            background-color: @list--row-background-color-hover;
        }
    }

    .is-sortable {
        position: relative;
        cursor: pointer;
        user-select: none;
    }

    .is-sorted .th__action::after {
        content: "";
        display: inline-block;
        inline-size: 8px;
        block-size: 8px;
        margin-inline-start: 4px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .is-sorted--up .th__action::after {
        .svg-arrow-sort-up(@base-link-color);
    }

    .is-sorted--down .th__action::after {
        .svg-arrow-sort-down(@base-link-color);
    }

    & &__cell-left {
        text-align: start;
    }

    & &__cell-right {
        text-align: end;
    }

    & &__cell-center {
        text-align: center;
    }

    & &__cell-actions,
    & &__cell-controls {
        padding-block: 6px;
    }

    & &__cell-actions {
        text-align: end;
    }

    & &__row--expandable {
        cursor: pointer;
    }

    & &__row-body-content {
        opacity: 0;
        transform: translateY(-8px);
        transition: opacity 0.3s, transform 0.3s;
        will-change: opacity, transform;

        &--entered {
            opacity: 1;
            transform: translateY(0);
        }

        &--exiting {
            opacity: 0;
            transform: translateY(-8px);
        }
    }

    &__table:not(&__table--vertical) > tbody > &__row--expanded {
        > td:first-child {
            box-shadow: inset 0 1px @base-link-color, inset 1px 0 #c4c4c4;
        }

        > td:last-child {
            box-shadow: inset 0 1px @base-link-color, inset -1px 0 #c4c4c4;
        }
    }

    &__table:not(&__table--vertical) > tbody > &__row--expanded + &__row-body {
        > td:first-child {
            box-shadow: inset 1px 0 #c4c4c4;
        }

        > td:last-child {
            box-shadow: inset -1px 0 #c4c4c4;
        }
    }

    &__table:not(&__table--vertical)
        > tbody
        > &__row-body
        + tr:not(.@{list--prefix-cls}__row--expanded)
        > td {
        border-block-start-color: #c4c4c4;
    }

    &__table:not(&__table--vertical) > tbody > tr:last-child.@{list--prefix-cls}__row-body > td {
        border-block-end: 1px solid #c4c4c4;
    }

    & &__row--expanded > td {
        background-color: @list--expanded-row-background;
    }

    &__table:not(&__table--vertical) > tbody > &__row--expanded > td {
        border-block-start-color: @base-link-color;
        box-shadow: inset 0 1px @base-link-color;
    }

    & &__row--parent.@{list--prefix-cls}__row--virtual + .@{list--prefix-cls}__row--child > td {
        border-block-start: none;
    }

    & &__row-body > td {
        border-block-start: none;
    }

    & &__cell-expander,
    & &__cell-reorderable {
        padding-block: 8px;
    }

    & &__cell-expander,
    & &__cell-selection,
    & &__cell-reorderable {
        inline-size: 1%;
    }

    & &__cell-reorderable + &__cell-expander,
    & &__table-thead &__cell-reorderable + &__cell-expander {
        padding-inline-start: 0;
    }

    & &__cell-body {
        padding-block-end: 20px;
    }

    & &__table-thead &__cell-expander {
        padding-inline-start: 9px;
    }

    &__row-expander {
        min-block-size: 24px;
        min-inline-size: 24px;
        inline-size: 24px;
        block-size: 24px;
        padding: 7px !important; // stylelint-disable-line declaration-no-important
    }

    &__row-expander-icon {
        inline-size: 10px;
        block-size: 10px;
        transition: 0.25s ease-in-out;
        margin: 0 !important; // stylelint-disable-line declaration-no-important
    }

    &__row--expanded &__row-expander-icon {
        transform: rotate(-180deg);
    }

    &__table--reorderable {
        .@{list--prefix-cls}__cell-reorderable-indicator {
            color: fade(@text-muted-color, 40%);
        }

        tr:hover .@{list--prefix-cls}__cell-reorderable-indicator {
            color: @text-muted-color;
        }

        > tbody[tabindex="0"]:focus {
            outline: none;
        }

        .@{list--prefix-cls}__wrapper--focus-visible
            .@{list--prefix-cls}__cell-reorderable-indicator {
            border-radius: 2px;
            box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.24);
        }
    }

    &__cell-reorderable-indicator {
        cursor: grab;
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
        inline-size: 24px;
        block-size: 24px;
        padding: 5px;
        text-align: center;

        svg {
            vertical-align: top;
        }
    }

    &--dragged {
        box-shadow: 0 1px 3px rgba(60, 64, 67, 0.12), 0 2px 8px rgba(60, 64, 67, 0.08);

        .@{list--prefix-cls}__cell-reorderable-indicator,
        .@{list--prefix-cls}__row--reorderable > td {
            cursor: grabbing;
        }
    }

    &__footer {
        margin-block-start: 24px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-block-end: 16px;
        min-block-size: 28px;
    }

    &__header-text {
        line-height: 16px;
        padding: 6px 0;
        display: inline-block;
    }

    &__header-buttons {
        display: inline-block;
    }

    &__spinner {
        margin: 2px;
        vertical-align: top;
    }

    &__truncate {
        display: table;
        table-layout: fixed;
        inline-size: 100%;
    }

    &--expandable:not(&__table--vertical) > thead > tr,
    &--expandable:not(&__table--vertical) > tbody > tr {
        > th,
        > td {
            padding-block: 18px;
        }

        > .@{list--prefix-cls}__cell-actions,
        > .@{list--prefix-cls}__cell-controls {
            padding-block: 14px;
        }

        > .@{list--prefix-cls}__cell-expander,
        > .@{list--prefix-cls}__cell-reorderable {
            padding-block: 16px;
        }
    }

    &__table--vertical {
        &,
        tbody,
        tr {
            display: block;
        }

        tr {
            padding: 8px;
            position: relative;
            border-block-start: 1px solid @list--border-color;
            background-color: @list--row-background-color;
        }

        .@{list--prefix-cls}__row-body + tr:not(.@{list--prefix-cls}__row--expanded) {
            border-block-start-color: #c4c4c4;
        }

        .@{list--prefix-cls}__row-body:last-child {
            border-block-end: 1px solid #c4c4c4;
        }

        th,
        td {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 8px;
            border-block-start: none;
            background-color: transparent;
        }

        td:empty {
            padding: 0;
        }

        .@{list--prefix-cls}__row-body-content-wrapper {
            flex: 1;
        }

        tbody tr.is-selected {
            background-color: @list--row-background-color-selected;
        }

        tbody
            tr:not(.is-selected):not(.@{list--prefix-cls}__row-body):not(.@{list--prefix-cls}__row--virtual):hover {
            background-color: @list--row-background-color-hover;
        }

        tbody tr.is-selected,
        tbody tr:not(.is-selected):not(.@{list--prefix-cls}__row-body):hover {
            > td,
            > th {
                background-color: transparent !important; // stylelint-disable-line declaration-no-important
            }
        }

        .@{list--prefix-cls}__row--expanded {
            background-color: @list--expanded-row-background;
            border-block-start-color: @base-link-color;
            box-shadow: inset 0 1px @base-link-color, inset 1px 0 #c4c4c4, inset -1px 0 #c4c4c4;
        }

        .@{list--prefix-cls}__row--expanded > td {
            background-color: transparent;
        }

        .@{list--prefix-cls}__cell-reorderable,
        .@{list--prefix-cls}__cell-expander,
        .@{list--prefix-cls}__cell-selection {
            position: absolute;
            inset-inline-start: 0;
            margin: 8px;
            padding: 0;
        }

        .@{list--prefix-cls}__cell-reorderable {
            inline-size: 24px;
            block-size: 24px;
            margin: 5px 6px;
            z-index: 3;
        }

        .@{list--prefix-cls}__cell-expander {
            inline-size: 24px;
            block-size: 24px;
            margin: 6px;
            z-index: 2;
        }

        .@{list--prefix-cls}__cell-selection {
            inline-size: 20px;
            block-size: 20px;
            z-index: 1;
        }

        .@{list--prefix-cls}__cell-reorderable ~ td,
        .@{list--prefix-cls}__cell-expander ~ td,
        .@{list--prefix-cls}__cell-selection ~ td {
            padding-inline-start: 36px;
        }

        .@{list--prefix-cls}__cell-reorderable + .@{list--prefix-cls}__cell-expander {
            padding-inline-start: 24px;
        }

        .@{list--prefix-cls}__cell-reorderable + .@{list--prefix-cls}__cell-selection,
        .@{list--prefix-cls}__cell-expander + .@{list--prefix-cls}__cell-selection {
            padding-inline-start: 28px;
        }

        .@{list--prefix-cls}__cell-expander + .@{list--prefix-cls}__cell-selection ~ td,
        .@{list--prefix-cls}__cell-reorderable + .@{list--prefix-cls}__cell-expander ~ td,
        .@{list--prefix-cls}__cell-reorderable + .@{list--prefix-cls}__cell-selection ~ td {
            padding-inline-start: 64px;
        }

        .@{list--prefix-cls}__cell-reorderable
            + .@{list--prefix-cls}__cell-expander
            + .@{list--prefix-cls}__cell-selection {
            padding-inline-start: 56px;
        }

        .@{list--prefix-cls}__cell-reorderable
            + .@{list--prefix-cls}__cell-expander
            + .@{list--prefix-cls}__cell-selection
            ~ td {
            padding-inline-start: 92px;
        }

        .@{list--prefix-cls}__cell-has-actions {
            padding-inline-end: 44px;
        }

        .@{list--prefix-cls}__cell-actions {
            position: absolute;
            inset-block-start: 12px;
            inset-inline-end: 16px;
            inline-size: 28px;
            block-size: 28px;
            box-sizing: content-box;
            padding: 0 !important; // stylelint-disable-line declaration-no-important
        }

        .@{list--prefix-cls}__row--expanded + .@{list--prefix-cls}__row-body {
            border-block-start: none;
            box-shadow: inset 1px 0 #c4c4c4, inset -1px 0 #c4c4c4;
        }

        .@{list--prefix-cls}__row--child {
            padding-inline-start: 24px;
        }

        .@{list--prefix-cls}__row--child .@{list--prefix-cls}__cell-reorderable,
        .@{list--prefix-cls}__row--child .@{list--prefix-cls}__cell-expander,
        .@{list--prefix-cls}__row--child .@{list--prefix-cls}__cell-selection {
            inset-inline-start: 24px;
        }

        .@{list--prefix-cls}__cell-inner-content {
            flex: auto;
            overflow-wrap: break-word;
            word-break: break-word;
            word-wrap: break-word;
        }

        .@{list--prefix-cls}__cell-title > .@{list--prefix-cls}__cell-inner-content {
            text-align: start;
        }

        .@{list--prefix-cls}__cell-inner-title + .@{list--prefix-cls}__cell-inner-content {
            padding-inline-start: 16px;
            text-align: end;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;