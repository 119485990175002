// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Icon/variables";
@import "../Text/variables";

@breadcrumbs--prefix-cls: ~"@{cls-prefix}breadcrumbs";

@breadcrumbs--font-size: 12px;
@breadcrumbs--line-height: 16px;
@breadcrumbs--color: @token-color-grey-8;
@breadcrumbs--separator-color: @token-color-grey-7;

.@{breadcrumbs--prefix-cls} {
    display: block;
    font-size: @breadcrumbs--font-size;
    color: @breadcrumbs--color;

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        display: flex;
        align-items: center;
        line-height: 16px;
        margin-block: 0 4px;
        margin-inline: 0;
        max-inline-size: 100%;
        white-space: nowrap;

        > :first-child {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__separator {
        flex: none;
        min-inline-size: 8px;
        min-block-size: 8px;
        margin-block: 0;
        margin-inline: 4px;
        text-align: center;
        color: @breadcrumbs--separator-color;

        .@{icon--prefix-cls} {
            margin-block: 0;
            margin-inline: -2px;
            vertical-align: top;
        }

        .@{icon--prefix-cls}--size-12 {
            margin-block-start: 2px;
        }
        .@{text--prefix-cls} + & {
            display: none;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;