// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";

@hint--prefix-cls: ~"@{cls-prefix}hint";
@hint--color: @text-muted-color;
@hint--font-size: 12px;
@hint--link-color: @base-link-color;
@hint--link-color-hover: @base-link-hover-color;

.@{hint--prefix-cls} {
    color: @hint--color;
    font-size: @hint--font-size;

    a {
        color: @hint--link-color;
    }

    a:hover {
        color: @hint--link-color-hover;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;