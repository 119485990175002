// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../Button/variables";
@import "../FormField/variables";
@import "../FormFieldCheckbox/variables";

.@{form--prefix-cls} {
    &__required {
        display: inline-block;
        inline-size: 7px;
        min-block-size: 8px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A//www.w3.org/2000/svg'%20fill%3D'none'%20viewBox%3D'4%204%207%208'%3E%3Cpath%20fill%3D'%23d02d4b'%20d%3D'M7%204.5a.5.5%200%200%201%201%200v2.63l2.26-1.31a.5.5%200%200%201%20.67.18.5.5%200%200%201-.18.68L8.5%208l2.26%201.32a.5.5%200%200%201%20.18.68.5.5%200%200%201-.67.18L8%208.87v2.63a.5.5%200%200%201-1%200V8.87l-2.26%201.31a.5.5%200%200%201-.67-.18.5.5%200%200%201%20.18-.68L6.5%208%204.25%206.68A.5.5%200%200%201%204.07%206a.5.5%200%200%201%20.67-.18L7%207.13V4.5z'/%3E%3C/svg%3E")
            50% no-repeat;
        background-size: 7px 8px;
        vertical-align: baseline;

        &::before {
            content: "\00a0";
        }

        .@{form-field--prefix-cls}__label &,
        .@{form-field-checkbox--prefix-cls} & {
            margin-inline-start: 4px;
            margin-inline-end: -10px;
        }
    }

    &__footer {
        margin-block-end: 0;

        .@{button--prefix-cls},
        .@{form--prefix-cls}__additional-button {
            margin-inline-end: 8px;
        }

        .@{button--prefix-cls}--arrow-forward {
            margin-inline-end: 16px;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;