// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Section/variables";

@drawer--prefix-cls: ~"@{cls-prefix}drawer";

.@{drawer--prefix-cls} {
    &__subheader {
        position: sticky;
        inset-block-start: 0;
        border-block-end: 1px solid @token-color-grey-3;
        transition: all 0.2s ease-in-out;
        overflow-y: hidden;
        inline-size: 100%;
        background-color: rgba(255, 255, 255, 0.9);
        flex: none;
        z-index: 1;
    }

    @supports (backdrop-filter: blur(10px)) {
        &__subheader {
            background-color: transparent;
            backdrop-filter: blur(10px);
        }
    }

    @media (max-width: @screen-xs) {
        &__subheader--hidden {
            inset-block-start: -52px;
        }
    }

    &__subheader-title {
        margin: 0;
        padding-block: 12px;
        padding-inline: 16px;
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__body {
        overflow: auto;
        position: relative;
        z-index: 1;
        flex-grow: 1;
    }

    &__inner-body {
        padding: 16px;
    }

    &__form {
        padding-block: 16px 0;
        padding-inline: 16px;
    }

    &__footer {
        margin-block-end: 0;
        padding-block: 16px 24px;
        padding-inline: 16px;
        border-block-start: 1px solid @token-color-grey-3;
        background-color: #f5f5f5;
        text-align: start;

        .@{section-item--prefix-cls}:not(.@{section-item--prefix-cls}--vertical)
            .@{section-item--prefix-cls}__title {
            order: 1;
            padding-inline-end: 0;
            padding-inline-start: 16px;
            inline-size: auto;
            text-align: end;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;