// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "variables";
@import "../../helpers/mixins.less";

/*
Icon

Markup:
<span class="icon {{.modifier}}">{{children}}</span>
<span class="icon {{.modifier}}"></span>
<span class="icon {{.modifier}}"><img src="..." alt="..."></span>
<span class="icon {{.modifier}}"><svg>...</svg></span>

Modifiers:
.icon--size-16
.icon--size-24
.icon--size-32
.icon--size-48
.icon--size-64
.icon--size-96
.icon--size-128
.icon--size-192

.icon--animation-spin

.icon--rotate-90
.icon--rotate-180
.icon--rotate-270

.icon--flip-horizontal
.icon--flip-vertical
*/

.@{icon--prefix-cls} {
    display: inline-block;
    inline-size: 16px;
    block-size: 16px;
    vertical-align: -3px;
    background-repeat: no-repeat;
    background-size: contain;

    img,
    svg {
        display: block;
        inline-size: 100%;
        block-size: 100%;
        overflow: hidden;
        fill: currentColor;
    }

    svg {
        -moz-transform: scale(1);
        pointer-events: none;
    }

    &--size-12 {
        .square(@icon--size-12);
    }
    &--size-16 {
        .square(@icon--size-16);
    }
    &--size-24 {
        .square(@icon--size-24);
    }
    &--size-32 {
        .square(@icon--size-32);
    }
    &--size-48 {
        .square(@icon--size-48);
    }
    &--size-64 {
        .square(@icon--size-64);
    }
    &--size-96 {
        .square(@icon--size-96);
    }
    &--size-128 {
        .square(@icon--size-128);
    }
    &--size-192 {
        .square(@icon--size-192);
    }

    &--success {
        color: @icon--success-color;
    }
    &--info {
        color: @icon--info-color;
    }
    &--warning {
        color: @icon--warning-color;
    }
    &--danger {
        color: @icon--danger-color;
    }
    &--inactive {
        color: @icon--inactive-color;
    }

    &--on-dark&--success {
        color: @icon--on-dark-success-color;
    }
    &--on-dark&--info {
        color: @icon--on-dark-info-color;
    }
    &--on-dark&--warning {
        color: @icon--on-dark-warning-color;
    }
    &--on-dark&--danger {
        color: @icon--on-dark-danger-color;
    }
    &--on-dark&--inactive {
        color: @icon--on-dark-inactive-color;
    }

    &--animation-spin {
        animation: @icon--animation-spin 1.4s linear infinite;
    }

    &--rotate-90 {
        transform: rotate(90deg);
    }
    &--rotate-180 {
        transform: rotate(180deg);
    }
    &--rotate-270 {
        transform: rotate(270deg);
    }

    &--flip-horizontal {
        transform: scaleX(-1);
    }
    &--flip-vertical {
        transform: scaleY(-1);
    }
}

@keyframes @icon--animation-spin {
    to {
        transform: rotate(360deg);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;