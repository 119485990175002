// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{item-link--prefix-cls} {
    display: flex;
    align-items: center;
    justify-content: center;
    min-block-size: 60px;
    padding-block: 10px;
    padding-inline: 20px;
    box-sizing: border-box;
    border: 1px dashed;
    border-radius: 2px;
    color: @item-link--color;
    font-weight: 600;
    background-color: @item-link--background-color;
    box-shadow: none;
    transition: background-color linear 0.25s, box-shadow linear 0.25s, border-color linear 0.25s;

    &:hover,
    &:active {
        border-style: solid;
        color: @item-link--hover-color;
        background-color: @item-link--background-color;
        box-shadow: none;
        cursor: pointer;
    }

    &:active {
        color: @item-link--active-color;
    }

    &:focus {
        box-shadow: @item-link--shadow-focus;
        outline: none;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;