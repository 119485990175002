// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Button/variables";
@import "../Icon/variables";
@import "../Form/variables";
@import "../Heading/variables";
@import "../Action/variables";

/*
Modifiers:
.popover--placement-top
.popover--placement-top-start
.popover--placement-top-end
.popover--placement-left
.popover--placement-left-start
.popover--placement-left-end
.popover--placement-bottom
.popover--placement-bottom-start
.popover--placement-bottom-end
.popover--placement-right
.popover--placement-right-start
.popover--placement-right-end

.popover__close--heading
.popover__arrow--heading
*/

@popover--prefix-cls: ~"@{cls-prefix}popover";

@popover--border-color: @token-color-grey-4;
@popover--close-color: @token-color-primary-2;
@popover--outer-space: 5px; // preventOverflow.padding modifier of popper.js (default value 5)
@popover--animation: ~"@{popover--prefix-cls}--fade";

.popover-intent(@color) {
    background-color: @color;

    .@{popover--prefix-cls}__arrow {
        box-shadow: 0 1px 0 @color;
    }
    .@{popover--prefix-cls}__arrow--heading {
        box-shadow: 0 1px 0 multiply(@color, #f5f5f5);
    }
    .@{popover--prefix-cls}__arrow::after {
        background-color: @color;
    }
    .@{popover--prefix-cls}__arrow--heading::after {
        background-color: multiply(@color, #f5f5f5);
    }
}

.@{popover--prefix-cls} {
    position: absolute;
    max-inline-size: 400px;
    box-sizing: border-box;
    font-family: @base-body-font-family;
    font-size: @base-body-font-size;
    line-height: @base-body-line-height;
    color: @base-body-color;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid @popover--border-color;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(60, 64, 67, 0.08), 0 1px 3px rgba(60, 64, 67, 0.16);
    text-align: start;
    white-space: normal;
    opacity: 1;
    animation: @popover--animation 0.3s ease-in-out;

    &--wide {
        max-inline-size: ~"calc(100% - @{popover--outer-space} * 2)";
    }

    &--width-xs {
        inline-size: 510px;
    }

    &--width-sm {
        inline-size: 640px;
    }

    &--width-md {
        inline-size: 900px;
    }

    &--width-lg {
        inline-size: 1200px;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        outline: none;
    }

    &__target {
        cursor: pointer;
    }

    &__arrow,
    &__arrow::after {
        position: absolute;
        display: block;
    }

    &__arrow {
        inline-size: 28px;
        block-size: 14px;
        overflow: hidden;
        border: none;
        box-shadow: 0 1px 0 #fff;

        &::after {
            content: "";
            inset-block-start: 6px;
            inset-inline-start: 4px;
            margin: 0;
            inline-size: 18px;
            block-size: 18px;
            border: 1px solid @popover--border-color;
            border-radius: 2px;
            background-color: #fff;
            box-shadow: 0 0 3px rgba(60, 64, 67, 0.16);
            transform: rotate(45deg) skew(-6deg, -6deg);
            transform-origin: center;
        }
    }

    &__arrow--heading {
        box-shadow: 0 1px 0 #f5f5f5;

        &::after {
            background-color: #f5f5f5;
        }
    }

    &__heading {
        display: flex;
        flex: none;
        position: relative;
        margin: 0;
        padding: logical 8px 12px;
        background-color: rgba(0, 0, 0, 0.04);
        border-start-start-radius: 2px;
        border-start-end-radius: 2px;
        border-end-start-radius: 0;
        border-end-end-radius: 0;

        .@{heading--prefix-cls} {
            flex-grow: 1;
            margin-block-end: 0;
        }
    }

    &__heading &__title {
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 20px;
    }

    &__heading &__extra {
        display: flex;
        align-items: center;
        flex: none;
        align-self: flex-start;
        margin: logical -4px auto -4px 0;
        padding-inline-start: 8px;
    }

    .@{heading--prefix-cls}--h1 + &__extra {
        margin-block: 6px;
    }

    .@{heading--prefix-cls}--h2 + &__extra {
        margin-block: 2px 1px;
    }

    .@{heading--prefix-cls}--h3 + &__extra {
        margin-block: -1px;
    }

    .@{heading--prefix-cls}--h4 + &__extra,
    .@{heading--prefix-cls}--h5 + &__extra {
        margin-block: -4px;
    }

    .@{heading--prefix-cls}--h6 + &__extra {
        margin-block: -7px;
    }

    &__extra .@{action--prefix-cls} + .@{action--prefix-cls} {
        margin-inline-start: 12px;
    }

    & &__close:not(&__close--heading) {
        float: inline-end;
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: 10;
        min-block-size: 18px;
        min-inline-size: 18px;
        margin-block: 4px;
        margin-inline: 0 4px;
        padding: 3px;
        line-height: 12px;
    }

    & &__close--heading {
        margin-inline: 8px -6px;
    }

    .@{button--prefix-cls}--ghost + &__close--heading {
        margin-inline-start: 0;
    }

    &__content {
        overflow: auto;

        .@{form--prefix-cls}__footer {
            margin-block-end: 10px;
        }
    }

    &__content-inner {
        box-sizing: border-box;
        padding: logical 12px 12px 16px;
        overflow-wrap: break-word;

        .@{form--prefix-cls}__footer {
            margin-block-end: 10px;
        }
    }

    &__close + &__content &__content-inner {
        padding-inline-end: 24px;
    }

    &__content-inner > :last-child {
        margin-block-end: 0;
    }

    &--placement-top-start,
    &--placement-top,
    &--placement-top-end {
        margin-block-end: 14px;

        .@{popover--prefix-cls}__arrow {
            inset-block-end: -14px;
            transform: rotate(180deg);
            margin-block: 0;
            margin-inline: 10px;
        }
    }

    &--placement-right-start,
    &--placement-right,
    &--placement-right-end {
        margin-inline-start: 14px;

        .@{popover--prefix-cls}__arrow {
            inset-inline-start: -21px;
            transform: rotate(-90deg);
            margin-block: 10px;
            margin-inline: 0;
        }
    }

    &--placement-bottom-start,
    &--placement-bottom,
    &--placement-bottom-end {
        margin-block-start: 14px;

        .@{popover--prefix-cls}__arrow {
            inset-block-start: -14px;
            margin-block: 0;
            margin-inline: 10px;
        }

        .@{popover--prefix-cls}__arrow::after {
            box-shadow: 0 0 1px rgba(60, 64, 67, 0.16);
        }
    }

    &--placement-left-start,
    &--placement-left,
    &--placement-left-end {
        margin-inline-end: 14px;
        position: relative;

        .@{popover--prefix-cls}__arrow {
            inset-inline-end: -21px;
            transform: rotate(90deg);
            margin-block: 10px;
            margin-inline: 0;
        }
    }

    &--success {
        .popover-intent(@brand-success);
    }

    &--info {
        .popover-intent(@brand-info);
    }

    &--warning {
        .popover-intent(@brand-warning);
    }

    &--danger {
        .popover-intent(@brand-danger);
    }

    @media (max-width: @screen-xs) {
        max-inline-size: ~"calc(100% - @{popover--outer-space} * 2)";
    }
}

@keyframes @popover--animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// stylelint-disable-next-line no-duplicate-selectors
.@{popover--prefix-cls} {
    // on-dark
    &--on-dark {
        color: rgba(255, 255, 255, 0.9);
        background-color: #1f2023;
        border-color: #222;
    }

    &--on-dark &__arrow {
        box-shadow: 0 1px 0 #1f2023;

        &::after {
            border-color: #222;
            background-color: #1f2023;
        }
    }

    &--on-dark &__arrow--heading {
        box-shadow: 0 1px 0 #2d2f34;

        &::after {
            background-color: #2d2f34;
        }
    }

    &--on-dark &__heading {
        background-color: #2d2f34;
    }

    &--on-dark &__heading,
    &--on-dark &__title {
        color: rgba(255, 255, 255, 0.9);
    }

    &--on-dark &__close {
        .@{icon--prefix-cls} {
            color: rgba(255, 255, 255, 0.65);
        }

        &:hover,
        &:focus {
            background-color: rgba(255, 255, 255, 0.09);
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.05);
        }

        &:hover .@{icon--prefix-cls},
        &:focus .@{icon--prefix-cls},
        &:active .@{icon--prefix-cls} {
            color: currentColor;
        }
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;