// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

/*
Toaster

Markup:
<div class="toaster toaster--top">
    <div class="toast toast--success" tabindex="0">
        <span class="toast__icon"><i className="icon-ok"></i></span>
        <span class="toast__content">toast message</span>
        <span class="toast__action"><span class="close"></span></span>
    </div>
</div>

Modifiers:
.toaster--top
*/

.@{toaster--prefix-cls} {
    position: fixed;
    inset-inline-end: 0;
    inset-inline-start: 0;
    padding-block: 52px 12px;
    padding-inline: 10px;
    pointer-events: none;
    transition: padding 0.3s ease-in-out;

    > span {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &--top-end {
        inset-block-start: 0;
    }

    &--bottom-end {
        inset-block-end: 0;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;