// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "variables";
@import "../Input/variables";
@import "../InPlaceEdit/variables";

.@{section--prefix-cls} {
    position: relative;
    margin-block-end: 20px;

    &__header {
        display: flex;
        align-items: flex-start;
        margin-block-end: 8px;
        padding: 0;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        color: @base-body-color;

        .@{in-place-edit--prefix-cls}__edit {
            margin-inline-start: -9px;
        }

        .@{in-place-edit--prefix-cls}__edit-input {
            inline-size: ~"calc(100% - 56px)";
        }

        .@{input--prefix-cls}__input {
            block-size: 28px;
            font-weight: 600;
        }
    }

    &--collapsible {
        padding-inline-start: 32px;

        .@{section--prefix-cls}__header {
            margin-inline-start: -32px;
        }

        .@{section--prefix-cls}__title {
            padding-inline-start: 4px;
            cursor: pointer;
        }
    }

    &__control-button--collapse {
        margin-inline-end: 0;
    }

    &__header-buttons {
        margin-inline-start: auto;
    }

    &__header-buttons &__control-button {
        margin-inline-start: 4px;
    }

    &--danger {
        background-color: @brand-danger;
        box-shadow: 0 0 0 8px @brand-danger;
    }

    &--warning {
        background-color: @brand-warning;
        box-shadow: 0 0 0 8px @brand-warning;
    }

    &--success {
        background-color: @brand-success;
        box-shadow: 0 0 0 8px @brand-success;
    }

    &--inactive {
        background-color: @token-color-grey-2;
        box-shadow: 0 0 0 8px @token-color-grey-2;
    }

    &--inactive &__header,
    &--inactive .@{section-item--prefix-cls} {
        opacity: 0.47;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;