// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Button/variables";
@import "../Dropdown/variables";

@split-button--prefix-cls: ~"@{cls-prefix}split-button";

.@{split-button--prefix-cls} {
    &__dropdown-trigger .@{button--prefix-cls} {
        background-color: @button--background-color;

        &:hover,
        &:focus,
        &.@{button--prefix-cls}--hovered {
            text-decoration: none;
            outline: none;
            color: @button--color-hover;
            background-color: @button--background-color-hover;

            .@{button--prefix-cls}__icon {
                color: @button--icon-color;
            }
        }

        &:active,
        &.@{button--prefix-cls}--active,
        .@{dropdown--prefix-cls}--opened& {
            color: @button--color-active;
            background-color: @button--background-color-active;
            box-shadow: @button--shadow-active;
        }
    }

    & .@{button--prefix-cls} + &__dropdown-trigger {
        margin-inline-start: 1px;
    }

    & .@{button--prefix-cls} + &__dropdown-trigger .@{button--prefix-cls} {
        border-start-start-radius: 0;
        border-start-end-radius: 2px;
        border-end-start-radius: 0;
        border-end-end-radius: 2px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;