// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables";
@import "../Button/variables";

/*
Button Group

Markup:
<div class="button-group">
  <button class="button" type="button">Button</a>
  <button class="button" type="button">Button</a>
  <div class="button-group">
    <button class="button" type="button">Button</a>
    <button class="button" type="button">Button</a>
  </div>
</div>
*/

@button-group--prefix-cls: ~"@{cls-prefix}button-group";

.@{button-group--prefix-cls} {
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    > .@{button--prefix-cls} {
        position: relative;
        flex: 0 1 auto;
        margin-block-end: 0;

        &:hover,
        &:active,
        &--active,
        &--hovered {
            z-index: @button-group--button-z-index;
        }

        &:focus {
            z-index: @button-group--button-z-index + 1;
        }
    }

    .@{button--prefix-cls} + .@{button--prefix-cls},
    .@{button--prefix-cls} + .@{button-group--prefix-cls},
    .@{button-group--prefix-cls} + .@{button--prefix-cls},
    .@{button-group--prefix-cls} + .@{button-group--prefix-cls} {
        margin-inline-start: 1px;
    }

    > .@{button--prefix-cls}:not(:first-child):not(:last-child):not(.@{button--prefix-cls}--ghost) {
        border-radius: 0;
    }

    > .@{button--prefix-cls}:first-child {
        margin-inline-start: 0;
    }

    > .@{button--prefix-cls}:first-child:not(:last-child):not(.@{button--prefix-cls}--ghost),
    > .@{button-group--prefix-cls}:first-child:not(:last-child)
        > .@{button--prefix-cls}:last-child:not(.@{button--prefix-cls}--ghost) {
        border-start-end-radius: 0;
        border-end-end-radius: 0;
    }

    > .@{button--prefix-cls}:last-child:not(:first-child):not(.@{button--prefix-cls}--ghost),
    > .@{button-group--prefix-cls}:last-child:not(:first-child)
        > .@{button--prefix-cls}:first-child:not(.@{button--prefix-cls}--ghost) {
        border-start-start-radius: 0;
        border-end-start-radius: 0;
    }

    > .@{button-group--prefix-cls}:not(:first-child):not(:last-child)
        > .@{button--prefix-cls}:not(.@{button--prefix-cls}--ghost) {
        border-radius: 0;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;