// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "variables";

.@{combobox-dropdown--prefix-cls} {
    &__popper {
        max-inline-size: ~"calc(100% - 20px)";
        margin-block: 4px;
        margin-inline: 0;
    }

    &__popper &__no-options {
        &,
        &:hover,
        &:focus {
            font-size: 12px;
            cursor: default;
        }
    }

    &__option {
        &:hover,
        &:focus {
            color: @combobox-dropdown--option-color;
            background-color: @combobox-dropdown--option-background-color;
        }
    }

    &__option--selected {
        &,
        &:hover,
        &:focus {
            color: @combobox-dropdown--option-selected-color;
            background-color: @combobox-dropdown--option-selected-background-color;
        }
    }

    &__menu {
        overflow-y: auto;
        max-block-size: 360px;
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;