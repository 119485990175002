// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

@import "../../helpers/variables.less";

@card-list--prefix-cls: ~"@{cls-prefix}card-list";
@card-list--loading-animation: ~"@{card-list--prefix-cls}--spin";

.@{card-list--prefix-cls} {
    position: relative;
    min-block-size: 50px;

    &__list--extended &__list-item,
    &__list--row &__list-item,
    & &__toolbar {
        margin-block-end: 24px;
    }

    &__list--tile &__list-item {
        margin-block-end: 8px;
    }

    &__content-blocker {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        background: rgba(255, 255, 255, 0.8)
            url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCI+PHBhdGggZD0iTTktMWwyIDJNMCAwbDEwIDEwTS0xIDlsMiAyIiBzdHJva2U9InJnYmEoMCwwLDAsMC4wNSkiLz48L3N2Zz4=");
        background-size: 10px 10px;
    }

    &__loader {
        display: flex;
        align-items: center;
    }

    &__loader-spinner {
        inline-size: 24px;
        block-size: 24px;
    }

    &__toolbar--select-all-btn {
        min-inline-size: 28px;
        padding-inline-start: 4px;
        padding-inline-end: 4px;
    }
}

@keyframes @card-list--loading-animation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@cls-prefix: pul-;@direction: ltr;@env: development;@cssSourceMap: true;